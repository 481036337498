<template>
  <div id="app">
    <nav>
      <ul class="menu-ul">
        <li class="menu-li menu-li-s" 
            @click="navigateTo('/')" 
            :class="{ active: isActive('/') }">首页</li>
        <li class="menu-li menu-li-l" 
            @click="navigateTo('/pic')" 
            :class="{ active: isActive('/pic') }">Base64转图片</li>
        <li class="menu-li menu-li-l" 
            @click="navigateTo('/time')" 
            :class="{ active: isActive('/time') }">时间戳转换</li>
      </ul>
    </nav>
    <router-view></router-view>
    <Link />
  </div>
</template>

<script>
import Link from './components/Link.vue'; // Import the Link component

export default {
  name: 'App',
  components: {
    Link // Register the Link component
  },
  methods: {
    navigateTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    isActive(path) {
      return this.$route.path === path;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu-ul {
  text-align: left;
  width: 1424px;
  font-weight: 500;
  margin: 12px 0 30px 12px;
}
.menu-li {
  float: left;
  margin-right: 8px;
  text-align: center;
}
.menu-li-s {
  width: 60px;
}

.menu-li-m {
  width: 90px;
}

.menu-li-l {
  width: 120px;
}

li.active {
  color: #333;
  border-bottom: 2px solid #E91919;
}

.menu-li:hover{
  color: #E91919;
  cursor: pointer;
}


</style>
