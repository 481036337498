import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import Pic from '../views/Img.vue'
import Time from '../views/TimeUtil.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '在线文本工具 - JSON格式化, XML格式化等',
      description: '在线文本工具-JSON格式化,XML格式化,URL编码,URL解码,Base64编码,Base64解码,md5加密,大写,小写,分栏提取,加单引号,加双引号,加逗号,前删除1个,后删除1个,去前后空格,去所有空格,删除空行,去重,逗号合并,空格合并,逗号分割,复制,清除',
      keywords: '在线文本工具-JSON格式化,XML格式化'
    }
  },
  {
    path: '/pic',
    name: 'Pic',
    component: Pic,
    meta: {
      title: '在线文本工具 - Base64转图片',
      description: 'Base64编码转图片,Base64转PDF,Base64转JPG,Base64转GIF,Base64转JPEG,Base64转PNG',
      keywords: 'Base64,图片转换,在线工具'
    }
  },
  {
    path: '/time',
    name: 'Time',
    component: Time,
    meta: {
      title: '在线文本工具 - 时间戳转换',
      description: '时间戳与日期时间互相转换工具',
      keywords: '时间戳,日期转换,在线工具'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 更新页面标题
  document.title = to.meta.title || '在线文本工具'

  // 更新 meta 标签
  const metaDescription = document.querySelector('meta[name="description"]')
  const metaKeywords = document.querySelector('meta[name="keywords"]')

  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || '')
  } else {
    const meta = document.createElement('meta')
    meta.name = 'description'
    meta.content = to.meta.description || ''
    document.head.appendChild(meta)
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', to.meta.keywords || '')
  } else {
    const meta = document.createElement('meta')
    meta.name = 'keywords'
    meta.content = to.meta.keywords || ''
    document.head.appendChild(meta)
  }

  next()
})

export default router
