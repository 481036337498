<template>
  <div class="time-util">
    <h1 class="seo-title">时间戳转换工具 - 在线时间戳与日期时间互转</h1>
    <h2>时间工具</h2>
    <div class="current-time">
      <div class="time-row">
        <span>当前时间：{{ currentDateTime }}</span>
        <button class="icon-button" @click="copyToClipboard(currentDateTime)" title="复制">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </button>
      </div>
      <div class="time-row">
        <span>当前时间戳：{{ currentTimestamp }}</span>
        <button class="icon-button" @click="copyToClipboard(currentTimestamp)" title="复制">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="input-group">
      <label for="timestamp-ms">时间戳 (毫秒):</label>
      <div class="input-button-group">
        <input id="timestamp-ms" v-model="timestampMs" @input="convertTimestampMsToDate" />
        <button class="icon-button" @click="copyToClipboard(timestampMs)" title="复制">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="input-group">
      <label for="timestamp-s">时间戳 (秒):</label>
      <div class="input-button-group">
        <input id="timestamp-s" v-model="timestampS" @input="convertTimestampSToDate" />
        <button class="icon-button" @click="copyToClipboard(timestampS)" title="复制">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="input-group">
      <label for="date">日期时间:</label>
      <div class="input-button-group">
        <input id="date" v-model="dateTime" type="text" @input="convertDateToTimestamp" />
        <button class="icon-button" @click="copyToClipboard(dateTime)" title="复制">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="showNotice" class="notice">
      {{ noticeMessage }}
      <button @click="closeNotice" class="notice-close">&times;</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  name: 'TimeUtil',
  setup() {
    const timestampMs = ref('')
    const timestampS = ref('')
    const dateTime = ref('')
    const convertedTimestamp = ref('')
    const formattedDateTime = ref('')
    const currentDateTime = ref('')
    const currentTimestamp = ref('')
    const showNotice = ref(false)
    const noticeMessage = ref('')

    let timer = null

    const formatDate = (date) => {
      return date.getFullYear() +
        '-' + String(date.getMonth() + 1).padStart(2, '0') +
        '-' + String(date.getDate()).padStart(2, '0') +
        ' ' + String(date.getHours()).padStart(2, '0') +
        ':' + String(date.getMinutes()).padStart(2, '0') +
        ':' + String(date.getSeconds()).padStart(2, '0') +
        '.' + String(date.getMilliseconds()).padStart(3, '0')
    }

    const convertTimestampMsToDate = () => {
      if (timestampMs.value) {
        const date = new Date(parseInt(timestampMs.value))
        dateTime.value = formatDate(date)
        timestampS.value = Math.floor(parseInt(timestampMs.value) / 1000).toString()
      }
    }

    const convertTimestampSToDate = () => {
      if (timestampS.value) {
        const date = new Date(parseInt(timestampS.value) * 1000)
        dateTime.value = formatDate(date)
        timestampMs.value = (parseInt(timestampS.value) * 1000).toString()
      }
    }

    const convertDateToTimestamp = () => {
      if (dateTime.value) {
        const parts = dateTime.value.match(/(\d+)/g)
        if (parts &&
          parts.length >= 6) {
          const date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5], parts[6] || 0)
          convertedTimestamp.value = date.getTime()
          timestampMs.value = date.getTime().toString()
          timestampS.value = Math.floor(date.getTime() / 1000).toString()
        } else {
          convertedTimestamp.value = 'Invalid date format'
        }
      }
    }

    const updateCurrentTime = () => {
      const now = new Date()
      currentDateTime.value = formatDate(now)
      currentTimestamp.value = now.getTime()
    }

    const setCurrentTime = () => {
      const now = new Date()
      timestampMs.value = now.getTime().toString()
      timestampS.value = Math.floor(now.getTime() / 1000).toString()
      dateTime.value = formatDate(now)
      formattedDateTime.value = dateTime.value
      convertedTimestamp.value = now.getTime()
      updateCurrentTime()
    }

    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(text)
        noticeMessage.value = '已复制到剪贴板'
        showNotice.value = true
        setTimeout(() => {
          showNotice.value = false
        }, 1500) // 1.5秒后自动隐藏通知
      } catch (err) {
        console.error('无法复制文本: ', err)
        noticeMessage.value = '复制失败，请手动复制'
        showNotice.value = true
      }
    }

    const closeNotice = () => {
      showNotice.value = false;
    }

    onMounted(() => {
      setCurrentTime()
      timer = setInterval(updateCurrentTime, 1000)
    })

    onUnmounted(() => {
      if (timer) {
        clearInterval(timer)
      }
    })

    return {
      timestampMs,
      timestampS,
      dateTime,
      convertedTimestamp,
      formattedDateTime,
      currentDateTime,
      currentTimestamp,
      showNotice,
      noticeMessage,
      convertTimestampMsToDate,
      convertTimestampSToDate,
      convertDateToTimestamp,
      copyToClipboard,
      closeNotice,
    }
  }
}
</script>

<style scoped>
.time-util {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 13px;
}

.current-time {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.time-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-button-group {
  display: flex;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
}

button:hover {
  background-color: #45a049;
  color: white;
}

.notice {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(92, 184, 92, 0.8);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.notice-close {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  padding: 0;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #333;
}

.seo-title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
